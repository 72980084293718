import { SearchResult } from '@/model/news/search-result';
import { Article, ArticleUtil } from '@/model/article';
import GenreBadge from '@/components/shared/genre-badge';
import KeywordBadge from '@/components/shared/keyword-badge';
import TaggedHeadline from '@/components/shared/tagged-headline';
import AgedDateFormat from '@/components/shared/aged-date-format';
import DateFormat from '@/components/shared/date-format';
import styles from '@/components/articles/article-list.module.scss';

interface Props {
  articles?: SearchResult<Article>[];
  onArticleClick: Function;
  colorizeDates?: boolean;
  showGenreTags?: boolean;
  hideAuthor?: boolean;
  showKeywords?: boolean;
}

export default function SearchResultList({
  articles,
  onArticleClick,
  colorizeDates = true,
  showGenreTags,
  hideAuthor,
  showKeywords,
}: Props) {
  return (
    <div className={'h-100 container-fluid'}>
      {articles?.map((value, index) => {
        let scale = 0.5;

        if (ArticleUtil.hasGenre(value.item, 'HEADLINE')) scale = 5;
        if (ArticleUtil.hasGenre(value.item, 'BULLET')) scale = 2;

        return (
          <div
            key={'article-' + value.item.uri}
            className={`row`}
            onClick={event => onArticleClick(value)}
          >
            <div className={'col-2 align-top  text-muted small'}>
              {colorizeDates ? (
                <AgedDateFormat
                  format={'MMM D h:mma'}
                  date={value.item.versioncreated}
                  scale={scale}
                />
              ) : (
                <DateFormat format={'MMM D h:mma'} date={value.item.versioncreated} />
              )}
            </div>
            <div className={'col-1'}>{value.score?.toFixed(3) || 'N/A'}</div>
            <div className={`col align-top text-text-dark ${styles.article}`}>
              {value.item.genre.map(value => (
                <span key={'genre-' + value.code} className={'small'}>
                  <GenreBadge genre={value} />
                </span>
              ))}

              <span className={styles.articleTitle}>
                {' '}
                <TaggedHeadline headline={value.item.headline} />
              </span>
              <div
                dangerouslySetInnerHTML={{ __html: value.item.summary }}
                className={`small  lh-sm fst-italic fw-lighter ${styles.articleSummary}`}
              ></div>

              {hideAuthor || (
                <div>
                  <span className={`align-top ${styles.articleAuthor}`}>
                    by {value.item.byline}
                  </span>
                </div>
              )}

              {
                <div>
                  {value.item.keywords.map(s => (
                    <span className={'small'}>
                      <KeywordBadge keyword={s} />{' '}
                    </span>
                  ))}
                </div>
              }
            </div>
          </div>
        );
      })}
    </div>
  );
}
