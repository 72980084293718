import axios from 'axios';
import articleApi from './article-api';
import selectApi from './select-api';
import preferencesApi from '@/api/preferences-api';
import opinionsApi from '@/api/opinions-api';
import sectionApi from '@/api/section-api';
import { authService } from '@/services';

axios.defaults.baseURL = process.env.REACT_APP_SELECT_HOST as string;

axios.interceptors.request.clear();
axios.interceptors.request.use(async value => {
  value.headers['Authorization'] = `Bearer ${(await authService.getAccessToken())?.access_token}`;
  return value;
});

export { articleApi, selectApi, preferencesApi, opinionsApi, sectionApi };
