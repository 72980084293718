import React from 'react';
import MainwireScroller from '@/components/scrollers/mainwire/mainwire-scroller';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import ArticleViewPage from '@/pages/news/article-view-page';
import { articleApi } from '@/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faRectangleList } from '@fortawesome/free-solid-svg-icons';

export class MainwireScrollerWidget implements NewsWidget {
  readonly id = '/mainwire';

  titleText: string = 'Policy Mainwire';
  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faRectangleList} /> {this.titleText}
      </>
    );
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <i className="fa-solid fa-bolt"></i> Policy Mainwire
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: <MainwireScroller />,
      },
      {
        path: ':articleId',
        element: <ArticleViewPage />,
        loader: async ({ params }) => {
          try {
            return await articleApi.getArticle(params.articleId || '');
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get defaultRoute(): string {
    return '';
  }
}
