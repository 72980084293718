import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  LocalWidgetRouterContext,
  LocalWidgetRouterState,
} from '@/widgets/widget-router/local-widget-router';
import { NewsWidget } from '@/widgets/news-widget';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';

export default function ReactWidgetRouter({
  children,
  widget,
}: React.PropsWithChildren<{ widget: NewsWidget }>) {
  console.log('react widget router');

  document.title = widget.titleText;
  return (
    <LocalWidgetRouterContext.Provider
      value={
        {
          basePath: widget.id,
          useLocal: false,
        } as LocalWidgetRouterState
      }
    >
      <WidgetContainerContext.Provider
        value={{
          setTitle(title?: string) {
            if (title && widget) {
              widget.titleText = title;
              document.title = widget.titleText;
            }
          },
        }}
      >
        <Outlet />{' '}
      </WidgetContainerContext.Provider>
    </LocalWidgetRouterContext.Provider>
  );
}
