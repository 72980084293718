import { CSSProperties } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

import { NewsEvent } from '@/model/calendar/news-event';
import { calendarLinkService } from '@/services';

export default function AddToCalendarButton({
  event,
  style,
}: {
  style?: CSSProperties | undefined;
  event: NewsEvent;
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" size="sm" style={style} variant={'link'}>
        <FontAwesomeIcon icon={faCalendarPlus} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={'bg-quaternary border-warning'}>
        <Dropdown.Item href={calendarLinkService.createGoogleLink(event)} target={'_blank'}>
          Add to Google Calendar
        </Dropdown.Item>
        <Dropdown.Item href={calendarLinkService.createOutlookLink(event)} target={'_blank'}>
          Add to Outlook Live Calendar
        </Dropdown.Item>
        <Dropdown.Item href={calendarLinkService.createOffice365Link(event)} target={'_blank'}>
          Add to Office 365 Calendar
        </Dropdown.Item>
        <Dropdown.Item
          href={calendarLinkService.createICalDownloadLink(event)}
          download={'event.ics'}
        >
          Download ICal File
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
