export default function TaggedHeadline({ headline }: { headline: string }) {
  const exp = /^([A-Z\s]*)(:)\s+/;

  const match = exp.exec(headline);

  if (match) {
    const trimmed = headline.replace(exp, '');

    return (
      <>
        <span className={'small me-3'}>
          <span className={'p-1 rounded-1 bg-neutral-100 text-text-dark'}>{match[1]}</span>
        </span>
        <span className="fs-4" style={{ fontFamily: 'Inter' }}>
          {trimmed}
        </span>
      </>
    );
  }

  return <>{headline}</>;
}
