import { useState } from 'react';

import useSubscription from '@/hooks/use-subscription';
import { Article } from '@/model/article';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';

interface BulletListTitleProps {
  section: string;
  title: string;
}

interface BulletListTitleState {
  count: number;
}

export default function BulletListTitle(props: BulletListTitleProps) {
  const [state, setState] = useState<BulletListTitleState>({ count: 0 });

  useSubscription<Article>(MessageServiceConstants.NEWS_ARTICLE_TOPIC, (message, headers) => {
    const sections: string[] = JSON.parse(headers.sections);

    if (sections.includes(props.section)) {
      setState({ count: (state.count || 0) + 1 });
    }
  });

  return (
    <>
      {props.title}
      {(state.count || 0) > 0 && false && (
        <sup>
          <span className="badge bg-danger">{state.count}</span>
        </sup>
      )}
    </>
  );
}
