import { useLoaderData, useNavigate, useNavigationType } from 'react-router-dom';
import { Action } from '@remix-run/router';

import CalendarEventDetails from '@/components/data-calendar/details/calendar-event-details';
import { NewsEvent } from '@/model/calendar/news-event';
import { Article } from '@/model/article';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';
import useWidgetLoaderData from '@/widgets/widget-router/use-widget-loader-data';

export default function EventPage() {
  const navigate = useWidgetNavigate();
  const event = useWidgetLoaderData();
  const navigationType = useNavigationType();

  return (
    <>
      <CalendarEventDetails
        newsEvent={event as NewsEvent}
        onClose={() => {
          if (navigationType === Action.Push) navigate(-1);
          else navigate('/daily/today');
        }}
        onArticleClick={(article: Article) => navigate(`/article/${article.uri}`)}
      />
    </>
  );
}
