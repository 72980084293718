import { useEffect, useState } from 'react';
import { GridLoader } from 'react-spinners';
import dayjs from 'dayjs';
import useSubscription from '@/hooks/use-subscription';
import useTimer from '@/hooks/use-timer';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import UpgradePanel from '@/components/shared/upgrade-panel';
import PageControl from '@/components/shared/page-control';
import AgedDateFormat from '@/components/shared/aged-date-format';
import TaggedHeadline from '@/components/shared/tagged-headline';

import styles from '@/components/articles/article-view.module.scss';
import articleListStyles from '@/components/articles/article-list.module.scss';
import { articleApi } from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import InfiniScroller from '@/components/scrollers/infini-scroller';
import PinButton from '@/components/pins/pin-button';
import BookmarkButton from '@/components/bookmarks/bookmark-button';

interface BulletListProps {
  section: string;
  articles?: Article[];

  onArticleClick: Function;
  dark?: boolean;
  title?: JSX.Element;
}

export default function BulletList(props: BulletListProps) {
  const [articles, setArticles] = useState<Article[]>();
  const [offset, setOffset] = useState<number>(0);
  const [forbidden, setForbidden] = useState<boolean>(false);

  const fetchData = (offset: number) => {
    articleApi.getArticlesBySection(props.section, { offset: offset, limit: 15 }).then(
      value => {
        setArticles(value.content);
        setForbidden(false);
      },
      reason => {
        setForbidden(true);
      },
    );
  };

  const setPage = (offset: number) => {
    setArticles(undefined);
    setOffset(offset);
    fetchData(offset);
  };

  useSubscription<Article>(
    MessageServiceConstants.NEWS_ARTICLE_TOPIC,
    (article: Article, headers) => {
      const sections: string[] = JSON.parse(headers.sections);

      if (sections.includes(props.section)) {
        const a = articles || [];

        const i = articles?.findIndex(value => value.uri === article.uri);

        if (i == null || i < 0) {
          a.unshift(article);
          a.pop();
        } else {
          a[i] = article;
        }

        setArticles(a);
      }
    },
  );

  useEffect(() => {
    setOffset(0);
    setArticles(undefined);
    fetchData(offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section]);

  useTimer(
    30000,
    () => {
      fetchData(offset);
    },
    false,
  );

  if (forbidden)
    return (
      <UpgradePanel
        heading={'Contact us to read more'}
        leadMessage={'I would like to try: ' + props.section}
      />
    );

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return articleListStyles.flashArticle;

    return '';
  };

  return (
    <InfiniScroller section={props.section} onArticlesChange={setArticles}>
      {articles?.map((value, index) => (
        <div key={'b-' + value.uri} className={`${flash(index, value.versioncreated)}`}>
          <div className={'d-flex justify-content-between align-items-baseline'}>
            <div>
              <AgedDateFormat format={'MMM D h:mma'} date={value.versioncreated} scale={10} />:{' '}
              <strong className="ms-3">
                <TaggedHeadline headline={value.headline} />
              </strong>
            </div>
            <div className={'flex-shrink-0'}>
              <PinButton article={value} />
              <BookmarkButton article={value} />
            </div>
          </div>
          <div>
            <small className={'text-text-dark ' + styles.article}>
              <ArticleBodyDisplay xhtml={value.body_xhtml} />
            </small>
          </div>
          <div className={'mb-2 small'}></div>
        </div>
      ))}
    </InfiniScroller>
  );
}
