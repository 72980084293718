import { useLoaderData, useNavigate, useNavigationType } from 'react-router-dom';
import { Action } from '@remix-run/router';

import { NewsEvent } from '@/model/calendar/news-event';
import AuctionDetails from '@/components/data-calendar/details/auction-details';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';
import useWidgetLoaderData from '@/widgets/widget-router/use-widget-loader-data';

export default function AuctionEventPage() {
  const navigate = useWidgetNavigate();
  const events = useWidgetLoaderData();
  const navigationType = useNavigationType();

  return (
    <>
      <AuctionDetails
        events={events as NewsEvent[]}
        onClose={() => {
          if (navigationType === Action.Push) navigate(-1);
          else navigate('/daily/today');
        }}
      />
    </>
  );
}
