import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';

export default function DashboardCreateModal({
  showCreateDashboardModal,
  setShowCreateDashboardModal,
  onCreate,
}: {
  showCreateDashboardModal: boolean;
  setShowCreateDashboardModal: (b: boolean) => void;
  onCreate: (s: string) => void;
}) {
  const [localDashboardName, setLocalDashboardName] = useState<string>('');

  return (
    <Modal
      show={showCreateDashboardModal}
      onHide={() => {
        setLocalDashboardName('');
        setShowCreateDashboardModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-3">
        <h4 className="text-center">Create dashboard</h4>
        <div className="text-center">
          <input
            className="w-75 my-3"
            type="text"
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              setLocalDashboardName(e.target.value);
            }}
            placeholder="Dashboard name"
            value={localDashboardName}
          ></input>
        </div>
        <Modal.Footer>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-warning"
            onClick={() => {
              setLocalDashboardName('');
              setShowCreateDashboardModal(false);
            }}
          >
            Cancel
          </Button>

          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-success"
            onClick={() => {
              onCreate(localDashboardName);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
