import { Article } from '@/model/article';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack as thumbtackSolid } from '@fortawesome/free-solid-svg-icons';
import { faThumbtackSlash as thumbtackOutline } from '@fortawesome/free-solid-svg-icons';

import useWidgetHost from '@/hooks/use-widget-host';
import { Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { qCode } from '@/model/concept-item';

export default function PinButton({
  article,
  onClick = () => {},
}: {
  article: Article;
  onClick?: () => void;
}) {
  const widgetHost = useWidgetHost();

  const id = '/pinned/' + article.uri;

  const pinned = widgetHost.activeWidgetIds.includes(id);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGenre, setSelectedGenre] = useState<string>();

  const onPin = () => {
    setShowModal(true);
  };

  const pinnableGenres = article.genre.filter(value => value.scheme != 'mnitype');

  useEffect(() => {
    if (!showModal) setSelectedGenre(undefined);
  }, [showModal]);

  return (
    <>
      <button
        onClick={onPin}
        style={{ fontFamily: 'Inter' }}
        className={`btn btn-sm btn-menu btn-link link-tertiary  `}
      >
        {!pinned && <FontAwesomeIcon icon={thumbtackSolid} />}
        {pinned && <FontAwesomeIcon icon={thumbtackOutline} />}
      </button>
      <Modal
        show={showModal}
        onBackdropClick={e => setShowModal(false)}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <h3>What would you like to Pin?</h3>
        </Modal.Header>
        <Modal.Body>
          {pinnableGenres.length > 0 && (
            <>
              <div className={'text-center'}>
                <p>Pin a series of articles:</p>
                <div className={'text-start mb-3'}>
                  {pinnableGenres.map(value => {
                    const alreadyPinned = widgetHost.activeWidgetIds.includes(
                      `/pinned-series/${qCode(value)}`,
                    );
                    const qcode = qCode(value);
                    return (
                      <div>
                        <Form.Check
                          type={'radio'}
                          checked={selectedGenre == qcode}
                          disabled={alreadyPinned}
                          label={`${value.name} ${alreadyPinned ? '(Already Pinned)' : ''}`}
                          onChange={event => event.target.checked && setSelectedGenre(qcode)}
                        />
                      </div>
                    );
                  })}
                </div>
                <p>
                  When a new edition of this series is available, the pinned series will update.
                </p>

                <Button
                  size={'lg'}
                  variant={'success'}
                  disabled={!selectedGenre}
                  onClick={event => {
                    widgetHost.addWidgetById(`/pinned-series/${selectedGenre}`);
                    setShowModal(false);
                  }}
                >
                  Pin This Series
                </Button>
              </div>
              <hr />
            </>
          )}

          {!pinned && (
            <>
              <div className={'text-center'}>
                <p>Pin just this article:</p>
                <Button
                  variant={'secondary'}
                  onClick={event => {
                    widgetHost.addWidgetById(id);
                    setShowModal(false);
                  }}
                >
                  Pin Single Article
                </Button>
              </div>

              <hr />
            </>
          )}
          {pinned && (
            <>
              <div className={'text-center'}>
                <p>Unpin this article:</p>
                <Button
                  variant={'error'}
                  onClick={event => {
                    widgetHost.removeWidgetById(id);
                    setShowModal(false);
                  }}
                >
                  Unpin Article
                </Button>
              </div>

              <hr />
            </>
          )}
          <div className={'text-center'}>
            <p>Whoops! Nothing:</p>
            <Button
              size={'sm'}
              variant={'warning'}
              onClick={event => {
                setShowModal(false);
              }}
            >
              Nevermind...
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
