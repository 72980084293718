import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchPanel from '@/components/search/search-panel';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';

export class SearchWidget implements NewsWidget {
  titleText: string = 'Search';

  readonly id = '/search';

  initialize(host: WidgetHost) {}

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faSearch} /> Search
      </>
    );
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faSearch} />
      </>
    );
  }

  get mainPanel(): JSX.Element {
    return <SearchPanel />;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '/search',
        element: <SearchPanel />,
      },
    ];
  }

  get defaultRoute(): string {
    return '/search';
  }
}
