import { Preferences } from '@/model/preferences/preferences';
import axios from 'axios';

const base = '/api/preferences/myMNI';

async function upsertPreferences(preferencesUpdate: Preferences): Promise<Preferences> {
  const preferences = (await axios.patch(base, preferencesUpdate)).data;

  return preferences;
}

async function getPreferences(): Promise<Preferences | null> {
  const preferences = (await axios.get(base)).data;

  return preferences;
}

export default {
  upsertPreferences,
  getPreferences,
};
