import DashboardWidgetPanel from '@/components/dashboard/dashboard-widget-panel';
import { Context } from '@/App';
import { useEffect, useState } from 'react';
import { MNIDashboard } from '@/model/preferences/preferences';
import { WidgetDockAdapter } from '@/components/dock/widget-dock-adapter';
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { NewsWidget } from '@/widgets/news-widget';

export type Breakpoint = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function DashboardContent({
  selectedDashboard,
  editMode,
  removeWidget,
  layout,
  setLayout,
  activeWidgets,
  onBreakpointChange,
  cols,
  onSave,
  setEditMode,
}: {
  selectedDashboard: MNIDashboard;
  editMode: boolean;
  removeWidget: (w: NewsWidget) => void;
  layout: Layouts;
  setLayout: (l: Layouts) => void;
  activeWidgets: NewsWidget[];
  onBreakpointChange: (b: Breakpoint) => void;
  cols: any;
  setEditMode: (b: boolean) => void;
  onSave: () => void;
}) {
  const getLayoutForWidget = (id: string): { x: number; y: number; w: number; h: number } => {
    return (
      layout[currentBreakpoint || '']?.find(value => value.i === id) || { x: 0, y: 0, w: 4, h: 4 }
    );
  };
  const openPopup = (url: string, width = 600, height = 800) => {
    window.open(url, url, `width=${width},height=${height}`);
  };
  function _preventTextSelect(a: any, b: any, c: any, d: any, event: MouseEvent) {
    event.preventDefault();
  }

  const [currentBreakpoint, setCurrentBreakPoint] = useState<Breakpoint>('lg');

  useEffect(() => {
    onBreakpointChange(currentBreakpoint);
  }, [currentBreakpoint]);

  return (
    <Context.Provider value={{ showMenu: false }}>
      {selectedDashboard && (
        <ResponsiveReactGridLayout
          draggableHandle={'.drag-handle'}
          onLayoutChange={(_currentLayout, allLayouts) => {
            let newLayout = layout;
            newLayout[currentBreakpoint] = allLayouts[currentBreakpoint];
            setLayout(newLayout);
          }}
          onBreakpointChange={newBreakpoint => {
            setCurrentBreakPoint(newBreakpoint as Breakpoint);
          }}
          onDrag={_preventTextSelect}
          onResize={_preventTextSelect}
          onResizeStop={_preventTextSelect}
          onDragStart={_preventTextSelect}
          onDragStop={_preventTextSelect}
          className="layout"
          isDraggable={editMode}
          isDroppable={editMode}
          isResizable={editMode}
          cols={cols}
          rowHeight={100}
          layouts={layout}
          margin={[10, 10]}
          resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
          containerPadding={[5, 5]}
        >
          {activeWidgets.map(value => {
            return (
              <div
                key={value.id}
                data-grid={getLayoutForWidget(value.id)}
                className={'overflow-hidden responsive-layout'}
              >
                <DashboardWidgetPanel
                  widget={value}
                  editMode={editMode}
                  onPopout={s => openPopup(s)}
                  onClose={() => removeWidget(value)}
                  onHeaderDoubleClick={() => {
                    setEditMode(!editMode);
                  }}
                  onSave={onSave}
                />
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      )}
    </Context.Provider>
  );
}
