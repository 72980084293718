import React, { createContext, useState } from 'react';
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import '@/index.scss';
import AuthProvider from '@/components/auth/auth-provider';
import LoginPage from '@/pages/auth/login-page';
import DockPage from '@/pages/dock-page';
import { AppContext } from '@/model/app-context';
import TokenExchangePage from '@/pages/auth/token-exchange-page';
import CalendarProvider, { CalendarContext } from '@/components/shared/calendar-provider';
import TestEnvIndicator from '@/components/shared/test-env-indicator';
import DashboardPage from '@/pages/dashboard-page';
import Auth0Page from '@/pages/auth/auth0-page';
import { Auth0Provider } from '@auth0/auth0-react';
import { convertWidgetToReactRoute } from '@/widgets/widget-router/convert-widget-to-react-route';
import useWidgetFactory from '@/hooks/use-widget-factory';
import ConnectionIndicators from '@/components/shared/connection-indicators';
import { convertWidgetFactoryToReactRoute } from '@/widgets/widget-router/convert-widget-factory-to-react-route';
import { localPreferencesService, themeService } from '@/services';
import PdfViewer, { ImageViewer } from '@/components/shared/pdf-viewer';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const auth0Client = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const Context = createContext<AppContext>({
  showMenu: true,
});

function App() {
  const [loaded, setLoaded] = useState<boolean>();

  const widgetFactory = useWidgetFactory();

  localPreferencesService.getLocalPreferences().then((value: any) => {
    if (value.timezoneType === 'explicit') dayjs.tz.setDefault(value.timezone);
    else dayjs.tz.setDefault();

    if (value) themeService.setThemeBasedOnUserPreferences(value);

    setLoaded(true);
  });

  if (!loaded) return <></>;

  let widgetRoutes: RouteObject[] = [];

  widgetFactory.getStaticWidgetList().forEach(value => {
    widgetRoutes = [...widgetRoutes, ...convertWidgetToReactRoute(value)];
  });

  widgetFactory.getDynamicWidgetFactories().forEach(value => {
    widgetRoutes = [...widgetRoutes, ...convertWidgetFactoryToReactRoute(value)];
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <CalendarProvider>
          <CalendarContext.Consumer>
            {value => <Navigate to={`/calendar/${value?.view}/${value?.date}`} />}
          </CalendarContext.Consumer>
        </CalendarProvider>
      ),
    },
    {
      path: '/login',
      element: <LoginPage />,
    },

    {
      path: '/auth0',
      element: <Auth0Page />,
    },
    {
      path: '/token',
      element: <TokenExchangePage />,
    },
    {
      path: '/token/:token',
      element: <TokenExchangePage />,
    },
    {
      path: '/dock',
      element: <AuthProvider />,
      shouldRevalidate: a => {
        console.log(a);
        return false;
      },
      children: [
        {
          path: '/dock',
          element: <DockPage />,
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <ConnectionIndicators>
          <AuthProvider />
        </ConnectionIndicators>
      ),
      shouldRevalidate: a => {
        console.log(a);
        return false;
      },
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage />,
        },
      ],
    },
    ...widgetRoutes.map(
      value =>
        ({
          path: value.path,
          element: (
            <ConnectionIndicators>
              <AuthProvider />
            </ConnectionIndicators>
          ),
          children: [value],
        }) as RouteObject,
    ),
  ]);

  return (
    <TestEnvIndicator>
      <div className="App">
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0Client}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/auth0`,
          }}
        >
          <Context.Provider value={{ showMenu: true }}>
            <RouterProvider router={router} />
            <ImageViewer />
            <PdfViewer />
          </Context.Provider>
        </Auth0Provider>
      </div>
    </TestEnvIndicator>
  );
}

export default App;
