import { NewsWidget } from '@/widgets/news-widget';
import { WidgetHost } from '@/widgets/widget-host';
import { RouteObject } from 'react-router-dom';
import React from 'react';
import PinnedArticlePage from '@/pages/news/pinned-article-page';
import { articleApi } from '@/api';
import { qCode } from '@/model/concept-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleList, faThumbtack } from '@fortawesome/free-solid-svg-icons';

export class PinnedSeriesWidget implements NewsWidget {
  constructor(private genre: string) {}

  get defaultRoute(): string {
    return '';
  }

  titleText: string = 'Pinned Series';

  get headerPanel(): JSX.Element {
    return (
      <>
        <span className={'w-100   position-relative'}>
          {' '}
          <FontAwesomeIcon icon={faThumbtack} /> {this.titleText}
        </span>
        <span
          className={'position-absolute  w-100 text-center '}
          style={{ top: 30, left: 0, zIndex: 100, pointerEvents: 'none' }}
        >
          <img src={'/pin.svg'} width={'60px'} alt={'pin'} />
        </span>
      </>
    );
  }

  get id(): string {
    return '/pinned-series/' + this.genre;
  }

  initialize(host: WidgetHost): void {}
  containerClass() {
    return 'note-yellow  sticky-note shadow-lg';
  }
  get routes(): RouteObject[] {
    return [
      {
        path: '/',
        element: <PinnedArticlePage className={'note-yellow'} />,
        loader: async ({}) => {
          try {
            const article = (
              await articleApi.getArticlesBySection(undefined, { includeGenres: [this.genre] })
            ).content[0];

            return {
              article,
              title: article.genre.find(value => qCode(value) == this.genre)?.name,
            };
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get shortTitle(): JSX.Element {
    return <>Test</>;
  }
}
