import { Article } from '@/model/article';
import styles from '@/components/articles/article-view.module.scss';
import dayjs from 'dayjs';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import './pinned-article.css';
export default function PinnedArticle({
  article,
  className = 'note-yellow',
}: {
  article: Article;
  className?: string;
}) {


  return (
    <div
      className={
        'h-100 text-black p-2 h-100 overflow-auto d-flex flex-column container-element ' + className
      }
      style={{}}
    >
      <div className={''}>
        <h3>{article.headline}</h3>
        <div className={'d-flex'}>
          <div className={'flex-grow-1'}>
            {article.summary && <div className={'small'}>{article.summary}</div>}
            {article.byline && <div className={'small'}>By {article.byline}</div>}
          </div>
          <div className={'small text-end'}>
            <div>{dayjs(article.versioncreated).format('D/MM HH:mm')}</div>
            <div><strong>{dayjs(article.versioncreated).fromNow()}</strong></div>
          </div>
        </div>
      </div>
      <hr/>

      <div className={'h-100 overflow-auto container-responsive-text '}>
        <ArticleBodyDisplay xhtml={article.body_xhtml} />
      </div>
    </div>
  );
}
