import React, { useState } from 'react';

import { Article } from '@/model/article';
import ArticleView from '@/components/articles/article-view';
import BulletList from '@/components/scrollers/bullet-list/bullet-list';
import BulletListTitle from '@/components/scrollers/bullet-list/bullet-list-title';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import MainwireScroller from '@/components/scrollers/mainwire/mainwire-scroller';
import { faCalendar, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BulletListMainView({ section }: { section: string }) {
  const [article, setArticle] = useState<Article | undefined>();

  if (article)
    return (
      <div className={'h-100 '}>
        <ArticleView article={article} onClose={() => setArticle(undefined)} />
      </div>
    );

  return (
    <div className={'h-100 '}>
      <BulletList dark={true} section={section} onArticleClick={(a: Article) => setArticle(a)} />
    </div>
  );
}

export class BulletListWidget implements NewsWidget {
  titleText: string = '';
  readonly id: string;
  constructor(
    private section: string,
    private title: string,
    private _shortTitle: string,
  ) {
    this.id = `/bullets/${section}`;
    this.titleText = title;
  }

  initialize(host: WidgetHost) {}

  get shortTitle(): JSX.Element {
    return <>{this._shortTitle}</>;
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faList} />{' '}
        <BulletListTitle title={this.titleText} section={this.section} />
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: `/`,
        element: <BulletListMainView section={this.section} />,
      },
    ];
  }
  get defaultRoute(): string {
    return `/`;
  }
}
