import { ConceptItem, qCode } from '@/model/concept-item';
import { Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

const sanitizeName = (s: string) => {
  return s.replaceAll(/bullet(s)?|news/gi, '').trim();
};

export default function ConceptItemFilters({
  conceptItems,
  onExcludedSubjectsChange,
  onExcludedGenresChange,
  onFilteredDataNameChange,
}: {
  conceptItems: ConceptItem[];
  onExcludedSubjectsChange: (s: string[]) => void;
  onExcludedGenresChange: (s: string[]) => void;
  onFilteredDataNameChange: (s?: string) => void;
}) {
  const [excludedSubjects, setExcludedSubjects] = useState<string[]>([]);
  const [excludedGenres, setExcludedGenres] = useState<string[]>([]);

  useEffect(() => {
    if (excludedGenres.length == 0 && excludedSubjects.length == 0)
      onFilteredDataNameChange(undefined);
    else {
      if (!conceptItems) return;

      const remaining = conceptItems
        .filter(conceptItem => {
          const qcode = `${conceptItem.scheme}:${conceptItem.code}`;

          return !excludedSubjects.includes(qcode) && !excludedGenres.includes(qcode);
        })
        .map(value => sanitizeName(value.name));

      let title = '';

      if (remaining.length == 1) {
        title = remaining[0];
      } else if (remaining.length > 1 && remaining.length <= 4) {
        const front = remaining.slice(0, remaining.length - 1);
        const back = remaining.slice(remaining.length - 1, remaining.length);
        title = front.join(', ') + ' and ' + back;
      }

      onFilteredDataNameChange(title);
    }
  }, [excludedSubjects, excludedGenres, conceptItems]);

  const filtersLeft = () => {
    return conceptItems.length - excludedSubjects.length - excludedGenres.length;
  };

  const excludeSubject = (s: string, exclude: boolean) => {
    if (exclude && filtersLeft() <= 1) return;

    let result: string[];

    if (exclude) result = [...excludedSubjects, s];
    else result = excludedSubjects.filter(value => value != s);

    setExcludedSubjects(result);
    onExcludedSubjectsChange(result);
  };

  const excludeGenre = (s: string, exclude: boolean) => {
    if (exclude && filtersLeft() <= 1) return;

    let result: string[];

    if (exclude) result = [...excludedGenres, s];
    else result = excludedGenres.filter(value => value != s);

    setExcludedGenres(result);
    onExcludedGenresChange(result);
  };
  const SubjectSwitch = ({ value }: { value: ConceptItem }) => {
    if (value.type == 'GENRE') {
      return (
        <Form.Check
          type={'switch'}
          onChange={event => excludeGenre(qCode(value), !event.target.checked)}
          checked={!excludedGenres.includes(qCode(value))}
          label={sanitizeName(value.name)}
          className={'d-inline-block pb-0 mb-0 me-1'}
          style={{ minHeight: 0 }}
        />
      );
    }

    return (
      <Form.Check
        type={'switch'}
        onChange={event => excludeSubject(qCode(value), !event.target.checked)}
        checked={!excludedSubjects.includes(qCode(value))}
        label={sanitizeName(value.name)}
        className={'d-inline-block pb-0 mb-0 me-1'}
        style={{ minHeight: 0 }}
      />
    );
  };

  return (
    <>
      {conceptItems.map(value => (
        <SubjectSwitch value={value} />
      ))}
    </>
  );
}
