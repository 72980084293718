import { NewsWidgetFactory } from '@/widgets/news-widget-factory';
import { NewsWidget } from '@/widgets/news-widget';
import React from 'react';
import { PinnedSeriesWidget } from '@/widgets/widgets/pinned/pinned-series-widget';
import { routeMatches } from '@/widgets/widget-router/route-matches';
import { PinnedArticleWidget } from '@/widgets/widgets/pinned/pinned-article-widget';

export class PinnedSeriesWidgetFactory implements NewsWidgetFactory {
  getListableUrls(): { name: string | JSX.Element; url: string }[] {
    return [
      {
        name: 'Central Bank Data Watch Analysis',
        url: '/pinned-series/mni-section:500163509',
      },
    ];
  }

  get headerPanel(): JSX.Element {
    return <>Article Series</>;
  }

  matchesTemplate(s: string): boolean {
    return !!routeMatches(this.path, s);
  }

  createInstance(s: string): NewsWidget {
    const match = routeMatches(this.path, s);

    if (match) return new PinnedSeriesWidget(match.genreQCode);

    throw new Error('invalid url');
  }
  get path(): string {
    return '/pinned-series/:genreQCode';
  }
}
