import { NewsWidgetFactory } from '@/widgets/news-widget-factory';
import { RouteObject } from 'react-router-dom';
import { WidgetFactoryWrapper } from '@/widgets/widget-router/widget-factory-wrapper';
import AuthProvider from '@/components/auth/auth-provider';

export function convertWidgetFactoryToReactRoute(widget: NewsWidgetFactory): RouteObject[] {
  return [
    {
      path: `${widget.path}/*`,
      element: (
        <div className={'p-2 vh-100'}>
          <WidgetFactoryWrapper factory={widget} />
        </div>
      ),
    },
  ];
}
