import dayjs, { Dayjs } from 'dayjs';

export default function DateFormat({
  format,
  date,
}: {
  format: string;
  date: string | Date | Dayjs | undefined;
}) {
  return <>{dayjs(date).tz().format(format)}</>;
}
