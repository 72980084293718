import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { MNIDashboard } from '@/model/preferences/preferences';

export default function DashboardViewControls({
  onDelete,
  onEdit,
  selectedDashboard,
  onDashboardSelect,
  dashboards,
  onDashboardCreate,
}: {
  onDelete: () => void;
  onEdit: () => void;
  onDashboardCreate: () => void;
  onDashboardSelect: (d: MNIDashboard) => void;
  selectedDashboard: MNIDashboard;
  dashboards: MNIDashboard[];
}) {
  return (
    <>
      <DropdownButton
        className={'me-2'}
        size={'sm'}
        variant={'outline-secondary'}
        style={{ fontFamily: 'Inter' }}
        title={selectedDashboard.name}
      >
        {dashboards.length > 1 ? (
          dashboards
            .filter(value => value.name !== selectedDashboard.name)
            .map(value => (
              <Dropdown.Item onClick={event => onDashboardSelect(value)} key={value.id}>
                {value.name}
              </Dropdown.Item>
            ))
        ) : (
          <Dropdown.Item disabled={true}>No dashboards</Dropdown.Item>
        )}
        {dashboards.length < 5 && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={onDashboardCreate}>Create dashboard</Dropdown.Item>
          </>
        )}
      </DropdownButton>
      <span>
        <button
          style={{ fontFamily: 'Inter' }}
          className={'btn btn-outline-error me-2 btn-sm'}
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </span>
      <span>
        <button
          style={{ fontFamily: 'Inter' }}
          className={'btn btn-outline-secondary me-2 btn-sm'}
          onClick={onEdit}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      </span>
      <span></span>
    </>
  );
}
