import dayjs, { Dayjs } from 'dayjs';

import DateFormat from '@/components/shared/date-format';

export default function AgedDateFormat({
  format,
  date,
  scale,
}: {
  format: string;
  date: string | Date | Dayjs | undefined;
  scale?: number;
}) {
  if (!scale) scale = 1;

  const today = dayjs(dayjs());
  const itemDate = dayjs(date);

  const days = today.diff(itemDate, 'minute') * scale;

  let c = '';

  //if (days < 60) c = 'text-warning-light';
  //else
  if (days < 180) c = 'text-tertiary';

  return (
    <span className={c} style={{ fontFamily: 'Inter', fontWeight: 700 }}>
      <DateFormat format={format} date={date} />{' '}
    </span>
  );
}
