import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { WidgetFactory } from '@/hooks/use-widget-factory';
import { WidgetDockAdapter } from '@/components/dock/widget-dock-adapter';
import { NewsWidget } from '@/widgets/news-widget';

export default function DashboardEditControls({
  dashboardName,
  onDashboardNameChange,
  onSaveDashboard,
  widgetFactory,
  onAddWidget,
  activeWidgets,
}: {
  dashboardName: string;
  onDashboardNameChange: (s: string) => void;
  onSaveDashboard: () => void;
  widgetFactory: WidgetFactory;
  onAddWidget: (w: NewsWidget) => void;
  activeWidgets: NewsWidget[];
}) {
  return (
    <>
      <input
        type="text"
        className={'form-control form-control-sm me-2'}
        onChange={e => {
          e.preventDefault();
          e.stopPropagation();
          onDashboardNameChange(e.target.value);
        }}
        value={dashboardName}
      ></input>
      <span>
        <button
          style={{ fontFamily: 'Inter' }}
          className={'btn btn-outline-success me-2 btn-sm'}
          onClick={() => onSaveDashboard()}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
        </button>
      </span>
      <span className={'d-inline me-2'}>
        <DropdownButton variant={'outline-secondary'} title={'Add Widget'} size={'sm'}>
          {widgetFactory
            .getStaticWidgetList()
            .filter(value => !activeWidgets.find(w => value.id === w.id))
            .map(value => (
              <Dropdown.Item onClick={() => onAddWidget(value)} key={value.id + '-widget'}>
                {value.headerPanel as JSX.Element}
              </Dropdown.Item>
            ))}

          <Dropdown.Divider />

          {widgetFactory
            .getDynamicWidgetFactories()
            .filter(value => value.getListableUrls().length > 0)
            .map((value, index) => {
              return (
                <DropdownButton
                  key={'fact-' + index}
                  title={<>{value.headerPanel}</>}
                  drop={'start'}
                  className={'dropdown-item'}
                >
                  {value.getListableUrls().map(item => {
                    return (
                      <Dropdown.Item
                        key={item.url + '-d-widget'}
                        onClick={() =>
                          onAddWidget(widgetFactory.getWidgetById(item.url) as NewsWidget)
                        }
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              );
            })}
        </DropdownButton>
      </span>
    </>
  );
}
