import { useEffect, useState } from 'react';
import { BoxBase, DockLayout, LayoutBase, LayoutData, PanelBase, TabData } from 'rc-dock';

import { WidgetDockAdapter } from '@/components/dock/widget-dock-adapter';
import { DataCalendarWidget } from '@/widgets/widgets/calendars/data-calendar-widget';
import { BulletListWidget } from '@/widgets/widgets/scrollers/bullet-list-widget';
import Menu from '@/components/shared/menu';
import { HotListWidget } from '@/widgets/widgets/hot-list-widget';
import { SettingsMenuWidget } from '@/widgets/widgets/settings-menu-widget';
import ConnectionIndicator from '@/components/shared/connection-indicator';
import { SearchWidget } from '@/widgets/widgets/search-widget';
import useDebounce from '@/hooks/use-debounce';
import { Context } from '@/App';

import '@/dock.scss';
import { preferencesApi } from '@/api';

const fiWidget = new WidgetDockAdapter(
  'fi',
  new BulletListWidget('fi-bullets', 'FI Bullets', 'FI'),
);
const fxWidget = new WidgetDockAdapter(
  'fx',
  new BulletListWidget('fx-bullets', 'FX Bullets', 'FX'),
);
const allBulletsWidget = new WidgetDockAdapter(
  'all-bullets',
  new BulletListWidget('all-bullets', 'All Bullets', 'All'),
);
const calWidget = new WidgetDockAdapter('calendar', new DataCalendarWidget());
const hotWidget = new WidgetDockAdapter('hot-list', new HotListWidget());
const settingsWidget = new WidgetDockAdapter('settings', new SettingsMenuWidget());
const searchWidget = new WidgetDockAdapter('search', new SearchWidget());

const widgets = [
  calWidget,
  hotWidget,
  allBulletsWidget,
  fiWidget,
  fxWidget,
  searchWidget,
  settingsWidget,
];

const defaultLayout: LayoutData = {
  dockbox: {
    mode: 'horizontal',
    children: [
      {
        minHeight: 70,
        mode: 'vertical',
        children: [
          {
            tabs: [
              allBulletsWidget as TabData,
              fiWidget as TabData,
              fxWidget as TabData,
              searchWidget as TabData,
            ],
          },
          {
            tabs: [hotWidget as TabData, settingsWidget as TabData],
          },
        ],
      },

      {
        minHeight: 30,
        tabs: [calWidget as TabData],
      },
    ],
  },
};

export default function DockPage() {
  const [dock, setDock] = useState<DockLayout>();
  const [layout, setLayout] = useState<LayoutBase>();
  const debouncedLayout = useDebounce(layout, 5000);
  const [currentTabId, setCurrentTabId] = useState<string>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    preferencesApi.getPreferences().then(val => {
      const savedDock = val?.dock;

      if (savedDock) {
        setLayout(savedDock);
      }

      setLoading(false);
    });
  }, []);

  const firstLeaf = (b: BoxBase | PanelBase) => {
    //let panel = b as PanelBase;
    let box = b as BoxBase;
    let panel = b as PanelBase;

    while (box?.children && box.children.length > 0) {
      panel = box.children[0] as PanelBase;
      box = box.children[0] as BoxBase;

      if (panel?.tabs && panel.tabs.length > 0) return panel.tabs[panel.tabs.length - 1].id;
    }

    return box?.id || b.id;
  };

  const activeTabId = () => {
    if (currentTabId && dock?.find(currentTabId)) return currentTabId;

    if (!layout) return undefined;

    return firstLeaf(layout.dockbox);
  };

  const widgetActive = (id: string) => {
    return !!dock?.find(id);
  };

  const toggleWidget = (w: WidgetDockAdapter) => {
    let existing = dock?.find(w.id);

    if (!existing) {
      let tabId = activeTabId();
      console.log('Adding tab', w.id, tabId, layout);

      if (tabId) dock?.dockMove(w as TabData, tabId, 'after-tab');
    } else {
      console.log('removing tab', w.id, existing);
      dock?.dockMove(existing as TabData, null, 'remove');
    }
  };

  const setRef = (r: DockLayout) => {
    console.log('Setting layout');

    setDock(r);
  };

  useEffect(() => {
    preferencesApi.upsertPreferences({ dock: debouncedLayout });
  }, [debouncedLayout]);

  return (
    <div
      className={
        'd-flex flex-row h-100 w-100 position-fixed text-start top-0 start-0 end-0 bottom-0 mni-dock'
      }
    >
      <div className={'flex-shrink-1 p-2 h-100 d-flex flex-column'}>
        {widgets.map(value => (
          <div className={'mb-1'} key={'widget-control-' + value.id}>
            <button
              style={{ fontFamily: 'Inter' }}
              className={`btn btn-sm w-100 ${
                widgetActive(value.id)
                  ? 'btn-secondary active text-base-light'
                  : 'btn-outline-secondary'
              }`}
              onClick={event => toggleWidget(value)}
            >
              {value.shortTitle}
            </button>
          </div>
        ))}

        <div className={'mb-auto'}></div>

        <div className={'text-center btn-group'}>
          <span
            className={
              'text-center btn btn-outline-secondary btn-sm disabled bg-quaternary ps-1 pe-1'
            }
          >
            <small>
              <ConnectionIndicator />
            </small>
          </span>
          <Menu padding={false} />
        </div>
      </div>
      <Context.Provider value={{ showMenu: false }}>
        <div className={'bg-quaternary flex-grow-1 flex-fill'}>
          {loading && (
            <div className="text-center mt-5">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <DockLayout
              ref={event => setRef(event as DockLayout)}
              layout={layout}
              defaultLayout={defaultLayout}
              style={{
                width: '100%',
                height: '100%',
              }}
              onLayoutChange={(newLayout, currentTabId, direction) => {
                console.log('LENGTH', newLayout.dockbox.children.length);
                console.log('MODE', newLayout.dockbox.mode);
                console.log(direction);

                setLayout(newLayout);
                setCurrentTabId(currentTabId);
              }}
            />
          )}
        </div>
      </Context.Provider>
    </div>
  );
}
