import { useState } from 'react';
import va from '@vercel/analytics';
import { authService } from '@/services';

const live: boolean = (process.env.REACT_APP_SEND_LEADS as string) === 'true' || false;

interface UpgradePanelProps {
  heading: string | JSX.Element;
  leadMessage: string;
}

interface Lead {
  firstName?: string;
  lastName?: string;
  email?: string;
  description?: string;
}

function encodeForm(lead: Lead, debug = true): string {
  const elements = [];

  if (debug) {
    elements.push('debug=1');
    elements.push(`debugEmail=${encodeURIComponent('sales.ops@marketnews.com')}`);
  }

  elements.push(`oid=00D50000000cZy5`);
  elements.push(`retURL=${encodeURIComponent('http://thankyou.marketnews.com')}`);

  if (lead.firstName) elements.push(`first_name=${encodeURIComponent(lead.firstName)}`);
  if (lead.lastName) elements.push(`last_name=${encodeURIComponent(lead.lastName)}`);
  if (lead.email) elements.push(`email=${encodeURIComponent(lead.email)}`);
  if (lead.description) elements.push(`description=${encodeURIComponent(lead.description)}`);

  return elements.join('&');
}

export default function UpgradePanel(props: UpgradePanelProps) {
  const [sent, setSent] = useState<boolean>(false);

  const send = () => {
    const body = encodeForm({
      firstName: authService.getCredentials()?.body.sub,
      lastName: props.leadMessage,
      email: authService.getCredentials()?.body.email,
      description: props.leadMessage,
    });

    if (live) {
      va.track('Upgrade request', {
        user: authService.getCredentials()?.body.email as string,
        message: props.leadMessage,
      });

      fetch('https://webto.salesforce.com/servlet/servlet.WebToLead', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body,
      })
        .then(
          value => {
            console.log('Send lead OK');
          },
          reason => {
            console.warn(reason);
          },
        )
        .finally(() => {
          setSent(true);
        });
    } else {
      setSent(true);
    }
  };

  return (
    <div className={'card bg-black shadow-lg border border-primary text-center container-element'}>
      <div className={'card-body text-light container-responsive-text'}>
        <h2 className={'text-secondary fw-bolder'}>{props.heading}</h2>
        <p className={'font-monospace'}>
          If you would like to upgrade your subscribe with MNI, the leading provider of central bank
          intelligence and analysis on the global fixed income, foreign exchange and energy markets,
          please contact us by clicking the button below.
        </p>
        {!sent && (
          <div className={'animatedBorder'}>
            <button
              className={'btn btn-lg btn-warning'}
              onClick={send}
              style={{ fontFamily: 'Inter' }}
            >
              Discover more
            </button>
          </div>
        )}
        {sent && (
          <div className={'alert alert-success'}>
            <div className={'fw-bold'}>Thank you!</div> We will contact you shortly.
          </div>
        )}
      </div>
    </div>
  );
}
