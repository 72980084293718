import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MNIDashboard } from '@/model/preferences/preferences';
import { Context } from '@/App';

import { faTableColumns } from '@fortawesome/free-solid-svg-icons';

import { Layouts, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import useWidgetFactory from '@/hooks/use-widget-factory';
import DashboardViewControls from '@/components/dashboard/dashboard-view-controls';
import DashboardEditControls from '@/components/dashboard/dashboard-edit-controls';
import { useSearchParams } from 'react-router-dom';
import DashboardDeleteModal from '@/components/dashboard/dashboard-delete-modal';
import DashboardCreateModal from '@/components/dashboard/dashboard-create-modal';
import DashboardContent, { Breakpoint } from '@/components/dashboard/dashboard-content';
import { NewsWidget } from '@/widgets/news-widget';
import { preferencesApi } from '@/api';
import { localPreferencesService, themeService } from '@/services';
import { WidgetHostContext } from '@/widgets/widget-host/widget-host-context';

export default function DashboardPage() {
  const [query, setQuery] = useSearchParams();

  const [dashboards, setDashboards] = useState<MNIDashboard[]>();
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState(false);
  const [showCreateDashboardModal, setShowCreateDashboardModal] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<MNIDashboard>();
  const [activeWidgets, setActiveWidgets] = useState<NewsWidget[]>();
  const [currentBreakpoint, setCurrentBreakPoint] = useState<string>('lg');
  const [layout, setLayout] = useState<Layouts>();

  const widgetFactory = useWidgetFactory();

  const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

  const initializeActiveWidgets = (currentLayout: MNIDashboard | undefined) => {
    const widgetsComp: NewsWidget[] = [];
    if (currentLayout && currentLayout.layouts) {
      const keys = Object.keys(currentLayout.layouts ?? {});
      const widgetsIds: string[] = [];
      keys.forEach(k => {
        currentLayout.layouts![k].forEach((wid: any) => {
          const exists = widgetsIds.indexOf(wid.i) > -1;
          if (!exists) widgetsIds.push(wid.i);
        });
      });
      widgetsIds.forEach(w => {
        let widget = widgetFactory.getWidgetById(w);

        if (widget) widgetsComp.push(widget);
      });
    }

    setActiveWidgets([...widgetsComp]);
    setSelectedDashboard(currentLayout);
    setLayout(currentLayout && currentLayout.layouts ? currentLayout.layouts : {});
  };

  useEffect(() => {
    if (!layout || !activeWidgets) return;

    const widgetId = query.get('widget');

    if (widgetId) {
      setTimeout(() => {
        const widget = widgetFactory.getWidgetById(widgetId);
        if (widget) addWidget(widget);

        console.log('Adding...', layout, activeWidgets);

        saveDashboard();
      }, 1000);

      query.delete('widget');
      setQuery(query);
    }
  }, [query, layout, activeWidgets]);

  useEffect(() => {
    if (initialized) return;

    console.log('Loading');

    localPreferencesService.getLocalPreferences().then(value => {
      themeService.setThemeBasedOnUserPreferences(value);
    });

    preferencesApi.getPreferences().then(val => {
      const localDashboards = val?.dashboard ?? [];
      setDashboards(localDashboards);

      if (localDashboards.length > 0) {
        const selectedDashboard = localStorage.getItem('selectedDashboard');
        const indexDashboard = selectedDashboard ? Number(selectedDashboard) : 0;
        const currentLayout =
          indexDashboard < localDashboards.length
            ? localDashboards[indexDashboard]
            : localDashboards[0];
        console.log('Loaded');
        initializeActiveWidgets(currentLayout);
      }

      setLoading(false);
    });

    setInitialized(true);

    return () => {
      localPreferencesService.getLocalPreferences().then((value: any) => {
        themeService.setThemeBasedOnUserPreferences(value);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Widgets', activeWidgets?.length);
  }, [activeWidgets]);

  const addWidget = (widget: NewsWidget) => {
    if (!layout || !activeWidgets) return;

    if (activeWidgets.find(value => value.id == widget.id)) return;

    const maxY =
      layout[currentBreakpoint]?.reduce((acc: number, value: Layout) => {
        return (acc = acc > value.y ? acc : value.y);
      }, 0) ?? 0;
    const maxX =
      layout[currentBreakpoint]?.reduce((acc: number, value: Layout) => {
        return (acc = value.y === maxY && acc <= value.x + value.w ? value.x + value.w : acc);
      }, 0) ?? 0;

    const colMaxWidth = cols[currentBreakpoint as Breakpoint];

    if (maxX !== 0) {
      //otherwise add to default place (0, 0)

      const isGreaterThanWidth = maxX + 4 > colMaxWidth;
      const valX = isGreaterThanWidth ? 0 : maxX;
      const valY = isGreaterThanWidth ? maxY + 4 : maxY;
    }
    if (!layout[currentBreakpoint]) layout[currentBreakpoint] = [];

    layout[currentBreakpoint].push({ w: 4, h: 4, x: 0, y: -1, i: widget.id });
    setLayout(layout);

    setActiveWidgets([...activeWidgets, widget]);
  };

  const removeWidget = (widget: NewsWidget) => {
    if (!activeWidgets) return;

    setActiveWidgets(activeWidgets.filter(value => value.id !== widget.id));
  };

  const removeWidgetById = (widget: string) => {
    if (!activeWidgets) return;

    setActiveWidgets(activeWidgets.filter(value => value.id !== widget));
  };

  const saveDashboard = () => {
    if (!dashboards) return;

    console.log('Saving dashboard:', selectedDashboard?.name);
    const newDashboards: MNIDashboard[] = [...dashboards];

    const newSelectedDashboard = newDashboards.find(d => d.id === selectedDashboard?.id);

    if (newSelectedDashboard && selectedDashboard) {
      newSelectedDashboard.name = selectedDashboard.name;
      newSelectedDashboard.layouts = layout;
    }

    preferencesApi.upsertPreferences({ dashboard: newDashboards }).then(value => {
      setDashboards(newDashboards);
      setSelectedDashboard(newSelectedDashboard);
      setEditMode(false);
    });
  };

  return (
    <div className="h-100 min-vh-100 dashboard-container">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img alt="logo" src={'/logo-white.svg'} height={'24'} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            style={{ height: '40px', fontFamily: 'Inter' }}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <div className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                  {selectedDashboard ? (
                    editMode ? (
                      <>
                        <DashboardEditControls
                          dashboardName={selectedDashboard.name}
                          onDashboardNameChange={s => {
                            console.log('Dashboard name change:', s);

                            if (selectedDashboard)
                              setSelectedDashboard({ ...selectedDashboard, name: s });
                          }}
                          onSaveDashboard={saveDashboard}
                          widgetFactory={widgetFactory}
                          onAddWidget={addWidget}
                          activeWidgets={activeWidgets || []}
                        />
                      </>
                    ) : (
                      <>
                        <DashboardViewControls
                          dashboards={dashboards || []}
                          selectedDashboard={selectedDashboard}
                          onDelete={() => {
                            setShowDeleteDashboardModal(true);
                          }}
                          onEdit={() => {
                            if (selectedDashboard) {
                              setEditMode(true);
                            }
                          }}
                          onDashboardCreate={() => setShowCreateDashboardModal(true)}
                          onDashboardSelect={value => {
                            if (!dashboards) return;

                            const dashboardsChange = dashboards;
                            let index: number;
                            dashboards.forEach((d, i) => {
                              if (d.name === value.name) index = i;
                            });
                            localStorage.setItem('selectedDashboard', index!.toString());
                            initializeActiveWidgets(dashboardsChange[index!]);

                            console.log(dashboardsChange[index!]);
                            setShowCreateDashboardModal(false);
                          }}
                        />
                      </>
                    )
                  ) : (
                    <button
                      className={'btn btn-outline-secondary me-2 btn-sm'}
                      style={{ fontFamily: 'Inter' }}
                      onClick={() => setShowCreateDashboardModal(true)}
                    >
                      <FontAwesomeIcon icon={faTableColumns} /> Create Dashboard
                    </button>
                  )}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div>
        <div className={'container-fluid'}>
          <WidgetHostContext.Provider
            value={{
              get activeWidgetIds(): string[] {
                return activeWidgets?.map(value => value.id) || [];
              },
              removeWidgetById(id: string) {
                removeWidgetById(id);
              },
              addWidgetById(id: string) {
                const widgetById = widgetFactory.getWidgetById(id);

                if (widgetById) {
                  addWidget(widgetById);
                  setEditMode(true);
                }
              },
            }}
          >
            <Context.Provider value={{ showMenu: false }}>
              {selectedDashboard && layout && (
                <DashboardContent
                  selectedDashboard={selectedDashboard}
                  editMode={editMode}
                  removeWidget={removeWidget}
                  layout={layout}
                  setLayout={setLayout}
                  activeWidgets={activeWidgets || []}
                  onBreakpointChange={setCurrentBreakPoint}
                  cols={cols}
                  setEditMode={setEditMode}
                  onSave={saveDashboard}
                />
              )}

              {!selectedDashboard && loading && (
                <div className="text-center mt-5">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!selectedDashboard && !loading && (
                <div className={'row'}>
                  <div className={'col-6 offset-3'}>
                    <div className={'alert alert-secondary m-5'}>
                      <h1>Welcome to MyMNI</h1>
                      <p>Dashboard not selected, please create one</p>{' '}
                      <Button size={'lg'} onClick={() => setShowCreateDashboardModal(true)}>
                        Create a Dashboard
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Context.Provider>
          </WidgetHostContext.Provider>
        </div>

        <DashboardCreateModal
          showCreateDashboardModal={showCreateDashboardModal}
          setShowCreateDashboardModal={setShowDeleteDashboardModal}
          onCreate={localDashboardName => {
            if (!localDashboardName || localDashboardName.length === 0 || !dashboards) {
              return;
            }

            const dashboardsChange = dashboards;
            dashboardsChange.push({
              name: localDashboardName,
              id: `${crypto.randomUUID()}-${new Date().getTime()}`,
            });
            setDashboards([...dashboardsChange]);
            setSelectedDashboard(dashboardsChange[dashboardsChange.length - 1]);
            initializeActiveWidgets(dashboardsChange[dashboardsChange.length - 1]);
            setShowCreateDashboardModal(false);
            setEditMode(true);
          }}
        />

        <DashboardDeleteModal
          showDeleteDashboardModal={showDeleteDashboardModal}
          setShowDeleteDashboardModal={setShowDeleteDashboardModal}
          onConfirm={() => {
            if (!dashboards) return;

            const dashboardsChange = dashboards;
            const index = dashboardsChange.findIndex(p => p.name === selectedDashboard?.name);
            if (index > -1) {
              dashboardsChange.splice(index, 1);
              setDashboards([...dashboardsChange]);
              saveDashboard();
              const selectedDashboard =
                dashboardsChange && dashboardsChange.length > 0 ? dashboardsChange[0] : undefined;
              setSelectedDashboard(selectedDashboard);
              initializeActiveWidgets(selectedDashboard);
            }
          }}
        />
      </div>
    </div>
  );
}
