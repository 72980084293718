import { createContext } from 'react';
import { AppContext } from '@/model/app-context';
import { WidgetHost } from '@/widgets/widget-host';

export const WidgetHostContext = createContext<WidgetHost>({
  addWidgetById(id: string) {
    console.warn('Noop widget host called');
  },
  removeWidgetById(id: string) {
    console.warn('Noop widget host called');
  },
  get activeWidgetIds(): string[] {
    return [];
  },
});
