export class LocalStorageUtil {
  getValue<T>(key: string): T | undefined {
    let json = window.localStorage.getItem(key);

    if (!json) return undefined;

    try {
      return JSON.parse(json) as T;
    } catch (e) {
      console.warn('Unable to get stored item', e);
      return undefined as T;
    }
  }

  saveValue(key: string, t: any): void {
    if (!t) this.removeValue(key);
    else window.localStorage.setItem(key, JSON.stringify(t));
  }

  removeValue(key: string): void {
    window.localStorage.removeItem(key);
  }
}
