import { useEffect, useState } from 'react';
import { RingLoader } from 'react-spinners';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';

import { NewsEvent, NewsEventUtil } from '@/model/calendar/news-event';
import { Observation } from '@/model/calendar/observation';
import { InstantAnswers } from '@/model/calendar/instant-answers';
import theme from '@/index.module.scss';
import useSubscription from '@/hooks/use-subscription';
import useTimer from '@/hooks/use-timer';
import { ApiError } from '@/services/errors/api-error';
import { AuctionObservation } from '@/model/calendar/auction-observation';
import { Permissions } from '@/model/permissions';
import RequirePermissions from '@/components/shared/require-permissions';
import UpgradePanel from '@/components/shared/upgrade-panel';
import CalendarDailyView from '@/components/data-calendar/list/calendar-daily-view';
import CalendarWeekView from '@/components/data-calendar/list/calendar-week-view';
import { CalendarEventFilter } from '@/components/data-calendar/calendar-filters';
import CalendarFilters from '@/components/data-calendar/calendar-filters';
import CalendarMenu from '@/components/data-calendar/calendar-menu';
import { selectApi } from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';

interface DataCalendarProps {
  date: string;
  view: 'daily' | 'weekly';
  onNavigate: ({ date, view }: { date: string; view: 'daily' | 'weekly' }) => void;
  onEventSelect: ({ date, event }: { date: string; event: NewsEvent }) => void;
}

export default function Calendar({ date, view, onNavigate, onEventSelect }: DataCalendarProps) {
  const dateFormat = 'YYYY-MM-DD';

  if (date === 'today') {
    date = dayjs().tz().format(dateFormat);
  }

  const [calendar, setCalendar] = useState<NewsEvent[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [eventFilter, setEventFilter] = useState<CalendarEventFilter>();
  const [filteredCalendar, setFilteredCalendar] = useState<NewsEvent[]>();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const unit = (view: string): 'week' | 'day' => (view === 'weekly' ? 'week' : 'day');

  const update = () => {
    let start = dayjs(date).tz().startOf(unit(view)).valueOf();
    let end = dayjs(date).tz().endOf(unit(view)).valueOf();

    selectApi.getCalendar(start, end).then(
      value => {
        value.sort((a, b) => {
          const timeDifference = new Date(a.date).getTime() - new Date(b.date).getTime();

          if (timeDifference === 0) {
            return a.title.localeCompare(b.title);
          }

          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });

        setCalendar(value);
      },
      (error: ApiError) => {
        if (!loaded) {
          setLoaded(true);
          setError(error);
          setCalendar([]);
        }
      },
    );
  };

  const reload = () => {
    setLoaded(false);
    update();
  };

  useTimer(
    30000,
    () => {
      update();
    },
    true,
  );

  useEffect(() => {
    if (eventFilter && calendar) {
      setFilteredCalendar(calendar.filter(eventFilter.filter));
      setLoaded(true);
    }
  }, [eventFilter, calendar]);

  useSubscription<Observation[]>(MessageServiceConstants.SELECT_TOPIC, message => {
    if (!calendar) return;

    message.forEach(observation => {
      calendar.forEach(value => {
        NewsEventUtil.mergeObservations(value, observation);
      });
    });

    setCalendar([...calendar]);
  });

  useSubscription<InstantAnswers[]>(MessageServiceConstants.INSTANT_ANSWERS_TOPIC, message => {
    if (!calendar) return;

    message.forEach(observation => {
      calendar.forEach(value => {
        NewsEventUtil.mergeInstantAnswers(value, observation);
      });
    });

    setCalendar([...calendar]);
  });

  useSubscription<AuctionObservation[]>(MessageServiceConstants.AUCTION_RESULTS_TOPIC, message => {
    if (!calendar) return;

    message.forEach(observation => {
      calendar.forEach(value => {
        if (NewsEventUtil.mergeAuctionData(value, observation)) console.log('Merged auction data');
      });
    });

    setCalendar([...calendar]);
  });

  return (
    <div className="container-element h-100">
      <div className={'h-100 d-flex flex-column container-responsive-text'}>
        <div className={'pb-2 sticky-top border-bottom bg-base-light'}>
          <CalendarMenu
            view={view}
            date={date}
            onNavigate={onNavigate}
            onToggleFilters={() => {
              setShowFilters(!showFilters);
            }}
            key={'menu'}
            filtersActive={eventFilter?.filtersActive}
          />

          <CalendarFilters
            onEventFilterChange={f => {
              setEventFilter(f);
            }}
            showFilters={showFilters}
          />
        </div>

        <div className={'overflow-auto h-100'}>
          {loaded && error && (
            <div className={'alert alert-danger'}>
              Error connecting to remote service.
              <button
                className={'btn btn-link'}
                onClick={() => reload()}
                style={{ fontFamily: 'Inter' }}
              >
                Retry
              </button>
            </div>
          )}

          {!error && loaded && (
            <div className={'h-100 overflow-auto'}>
              <RequirePermissions
                allow={Permissions.ANY_SELECT_PRODUCT}
                fallback={
                  <UpgradePanel
                    heading={'Try the MNI High Speed Calendar'}
                    leadMessage={'I would like to try the MNI High Speed Calendar'}
                  />
                }
              >
                {view === 'daily' && (
                  <CalendarDailyView
                    calendar={filteredCalendar}
                    setEvent={(e: NewsEvent) => onEventSelect({ date, event: e })}
                  />
                )}
                {view === 'weekly' && (
                  <CalendarWeekView
                    calendar={filteredCalendar}
                    setEvent={(e: NewsEvent) => onEventSelect({ date, event: e })}
                    start={dayjs(date).tz()}
                  />
                )}
              </RequirePermissions>
            </div>
          )}
          {!loaded && (
            <div className={'p-5 m-5 d-flex justify-content-around flex-row'}>
              <RingLoader color={theme.info} size={100} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
