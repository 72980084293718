import useWidgetLoaderData from '@/widgets/widget-router/use-widget-loader-data';
import PinnedArticle from '@/components/pins/pinned-article';
import { Article } from '@/model/article';
import { useContext, useEffect } from 'react';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import {GridLoader} from 'react-spinners';
import Loading from '@/components/shared/loading';

export default function PinnedArticlePage({ className }: { className?: string }) {
  const { article, title } = useWidgetLoaderData() as { article: Article; title: string };

  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);

  useEffect(() => {
    widgetContainer.setTitle(title);
  }, [title]);

  if (!article)
    return <>
      <div className={''}>
        <Loading type={'grid'}/>
      </div>
    </>

  return <PinnedArticle article={article as Article} className={className} />;
}
