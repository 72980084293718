import { useEffect, useRef, useState } from 'react';
import { addClickHandlersToElement } from '@/components/shared/pdf-viewer';
import styles from '@/components/articles/article-view.module.scss';
import useSubscription from '@/hooks/use-subscription';
import { messageService } from '@/services';

export default function ArticleBodyDisplay({ xhtml }: { xhtml: string }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [toggle, setToggle] = useState<boolean>(true);

  useEffect(() => {
    if (ref && xhtml) {
      if (ref.current)
        addClickHandlersToElement(ref.current, () => {
          messageService.publish('article-model', {});
        });
      const anchors = ref.current?.getElementsByTagName<'a'>('a');

      if (anchors) {
        for (const a of anchors) {
          a.setAttribute('target', '_blank');
        }
      }
    }
  }, [ref.current, xhtml, toggle]);

  useSubscription('article-model', message => {
    setToggle(false);
  });

  useEffect(() => {
    setToggle(true);
  }, [toggle]);

  return (
    <>
      {' '}
      <div ref={ref} dangerouslySetInnerHTML={{ __html: xhtml }} className={styles.article}></div>
      {toggle && <span />}
    </>
  );
}
