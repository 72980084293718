import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
  faShareNodes,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';
import { Button } from 'react-bootstrap';
import ClipboardButton from '@/components/shared/clipboard-button';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';

type Layout = { x: number; y: number; w: number; h: number };
export default function DashboardWidgetPanel({
  widget,
  editMode,
  onPopout,
  onClose,
  onHeaderDoubleClick,
  onSave,
}: {
  widget: NewsWidget;
  editMode: Boolean;
  onPopout: (s: string) => void;
  onClose: () => void;
  onHeaderDoubleClick: () => void;
  onSave: () => void;
}) {
  const [widgetPath, setWidgetPath] = useState<string>();

  const [hovering, setHovering] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element>();

  return (
    <div
      className={` h-100 `}
      style={{ userSelect: 'none' }}
      onMouseEnter={event => setHovering(true)}
      onMouseOver={event => setHovering(true)}
      onMouseLeave={event => setHovering(false)}
    >
      <div
        className={`${widget.containerClass ? widget.containerClass() : 'card rounded-4 h-100'} shadow `}
      >
        <WidgetContainerContext.Provider
          value={{
            setTitle: title1 => {
              widget.titleText = title1 || widget.titleText;
              setTitle(widget.headerPanel);
            },
          }}
        >
          <div className={`card-body h-100 d-flex flex-column`} style={{}}>
            <div className={'d-flex justify-content-between align-items-center ps-3'}>
              <strong
                onDoubleClick={onHeaderDoubleClick}
                style={{ fontFamily: 'Inter', fontWeight: 700 }}
                className={
                  'drag-handle flex-grow-1 d-flex align-items-center gap-1  text-truncate '
                }
              >
                {title || (widget.headerPanel as JSX.Element)}
              </strong>
              <span
                onDrag={event => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                {!editMode && (
                  <>
                    <ClipboardButton text={window.location.href + `?widget=${widget.id}`}>
                      <button className={'btn btn-link '} onClick={event => {}}>
                        <FontAwesomeIcon icon={faShareNodes} />
                      </button>
                    </ClipboardButton>

                    <button
                      className={'btn btn-link '}
                      onClick={() => onPopout(convertWidgetToReactPath(widget.id, widgetPath))}
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </button>
                  </>
                )}
                {editMode && (
                  <button className={'btn btn-link link-danger'} onClick={onClose}>
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </button>
                )}
              </span>
            </div>
            <div className={'overflow-hidden flex-shrink-1 h-100 position-relative '}>
              {editMode && (
                <div
                  className={
                    'position-absolute z-3 w-100 h-100 bg-body-secondary bg-opacity-75 d-flex text-center flex-column'
                  }
                >
                  <div className={'mt-auto mb-auto opacity-100 text-white'}>
                    <div className={'m-2 small'}>
                      Editing Dashboard. Grab the title bar above to move, and use handles to
                      resize. Save to continue.
                    </div>
                    <div>
                      {hovering && (
                        <Button
                          size={'sm'}
                          className={'opacity-100'}
                          onClick={onSave}
                          variant={'primary'}
                        >
                          Save Dashboard
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <LocalWidgetRouter widget={widget} onNavigate={setWidgetPath} />
            </div>
          </div>
        </WidgetContainerContext.Provider>
      </div>
    </div>
  );
}
