import React, { useContext, useEffect, useRef, useState } from 'react';
import { GridLoader } from 'react-spinners';
import dayjs from 'dayjs';
import useSubscription from '@/hooks/use-subscription';
import useTimer from '@/hooks/use-timer';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import UpgradePanel from '@/components/shared/upgrade-panel';
import PageControl from '@/components/shared/page-control';
import { articleApi, sectionApi } from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { Section } from '@/model/news/section';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { faArrowUp, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConceptItemFilters from '@/components/scrollers/concept-item-filters';
import { debounce } from '@/util/debounce';

interface ScrollerProps {
  section: string;
  articles?: Article[];
  dark?: boolean;
  title?: JSX.Element;
  onArticlesChange: (articles?: Article[]) => void;
}

const pageSize = 10;
export default function InfiniScroller(props: React.PropsWithChildren<ScrollerProps>) {
  const [articles, setArticles] = useState<Article[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [section, setSection] = useState<Section>();
  const [excludedSubjects, setExcludedSubjects] = useState<string[]>([]);
  const [excludedGenres, setExcludedGenres] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);

  const [hasNewArticles, setHasNewArticles] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<{
    height: number;
    scrollPosition: number;
    visibleHeight: number;
    scrollTop: number;
  }>();

  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  const fetchData = (offset: number, oldArticles: Article[]) => {
    setLoading(true);
    setOffset(offset);
    setArticles(oldArticles);

    console.log('Loading offset', offset);
    articleApi
      .getArticlesBySection(props.section, {
        offset: offset,
        limit: pageSize,
        excludeSubjects: excludedSubjects,
        excludeGenres: excludedGenres,
      })
      .then(
        value => {
          mergeArticles(oldArticles, value.content);

          setForbidden(false);
          setLoading(false);
        },
        reason => {
          setForbidden(true);
          setLoading(false);
        },
      );
  };

  const mergeArticles = (oldArticles: Article[], newArticles: Article[]) => {
    let a = oldArticles;

    newArticles.forEach(article => {
      const i = a?.findIndex(value => value.uri === article.uri);

      if (i == null || i < 0) {
        console.debug('Adding article');
        a.unshift(article);
      } else {
        console.debug('Updating article', i);
        a[i] = article;
      }
    });

    a = a.sort(
      (a1, b) => new Date(b.versioncreated).getTime() - new Date(a1.versioncreated).getTime(),
    );

    setArticles([...a]);
  };

  useSubscription<Article>(
    MessageServiceConstants.NEWS_ARTICLE_TOPIC,
    (article: Article, headers) => {
      const sections: string[] = JSON.parse(headers.sections);

      console.debug('Received article', sections, article);

      if (sections.includes(props.section)) {
        mergeArticles(articles, [article]);
      }
    },
  );

  useEffect(() => {
    setOffset(0);
    setArticles([]);
    setLoading(true);
    fetchData(offset, []);
    sectionApi.getSectionByCode(props.section).then(value => {
      setSection(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section]);

  useEffect(() => {
    props.onArticlesChange(articles);
  }, [articles]);

  useEffect(() => {
    fetchData(0, []);
    scrollToTop();
  }, [excludedSubjects, excludedGenres]);

  useEffect(() => {
    console.log('number of articles', articles.length);

    if (articles.length > 0) {
      if (new Date().getTime() - new Date(articles[0].versioncreated).getTime() < 300_000) {
        setHasNewArticles(true);
      } else setHasNewArticles(false);
    }
  }, [articles]);

  const titleChange = (s?: string) => {
    if (!s) widgetContainer.setTitle(undefined);
    else {
      widgetContainer.setTitle(s);
    }
  };

  const loadIfNearBottom = (height: number, scrollPosition: number) => {
    if (loading) {
      console.log('Already loading....');
      return;
    }

    if (scrollPosition >= height - 100) {
      console.log('Loading from scroll event', articles.length);
      setLoading(true);
      fetchData(offset + pageSize, articles);
    }
  };

  useEffect(() => {
    if (scrollPosition) loadIfNearBottom(scrollPosition.height, scrollPosition.scrollPosition);
  }, [scrollPosition]);

  const scrollToTop = () => {
    const current = scrollBoxRef.current;
    if (current) current.scrollTo({ top: 0 });
  };

  if (forbidden)
    return (
      <UpgradePanel
        heading={'Contact us to read more'}
        leadMessage={'I would like to try: ' + props.section}
      />
    );

  return (
    <div className={'container-element h-100 '}>
      <div className={'h-100 d-flex flex-column container-responsive-text '}>
        <div className={'d-flex justify-content-around flex-wrap'}>
          {section && (
            <ConceptItemFilters
              conceptItems={section.subjects}
              onExcludedSubjectsChange={setExcludedSubjects}
              onExcludedGenresChange={setExcludedGenres}
              onFilteredDataNameChange={titleChange}
            />
          )}
        </div>

        {!articles && (
          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>
        )}

        <div
          className={'flex-fill mt-3 overflow-auto '}
          ref={scrollBoxRef}
          onScroll={event => {
            const target = event.target as HTMLDivElement;

            const scrollHeight = target.scrollHeight;
            const scrollPosition = target.scrollTop + target.offsetHeight;

            if (scrollPosition == scrollHeight) target.scrollTo({ top: target.scrollTop - 110 });

            setScrollPosition({
              scrollPosition,
              height: scrollHeight,
              visibleHeight: target.clientHeight,
              scrollTop: target.scrollTop,
            });
          }}
        >
          {props.children}

          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>

          {scrollPosition && scrollPosition.scrollTop > scrollPosition.visibleHeight && (
            <div className={'position-absolute bottom-0 d-flex w-100 justify-content-between '}>
              <div className={'bg-body-secondary '}>
                <a
                  href={''}
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    scrollToTop();
                  }}
                >
                  <FontAwesomeIcon icon={faArrowUp} /> Top ({articles.length} items)
                </a>
              </div>

              <div className={'bg-body-secondary'}>
                {hasNewArticles && (
                  <a
                    href={''}
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      scrollToTop();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowUp} /> New Article Available
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
