import { Button, Modal } from 'react-bootstrap';

export default function DeleteBookmarkModal({
  showDeleteBookmarkModal,
  setShowDeleteBookmarkModal,
  onConfirm,
}: {
  showDeleteBookmarkModal: boolean;
  setShowDeleteBookmarkModal: (s: string | null) => void;
  onConfirm: () => void;
}) {
  return (
    <Modal
      show={showDeleteBookmarkModal}
      onHide={() => {
        setShowDeleteBookmarkModal(null);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-3">
        <h4 className="text-center">Delete bookmark</h4>
        <div className="text-center my-3">Are you sure you want to delete this bookmark?</div>
        <Modal.Footer>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-warning"
            onClick={() => {
              setShowDeleteBookmarkModal(null);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-danger"
            onClick={() => {
              onConfirm();
              setShowDeleteBookmarkModal(null);
            }}
          >
            Accept
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
