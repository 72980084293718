import dayjs from 'dayjs';
import { GridLoader } from 'react-spinners';

import { Article, ArticleUtil } from '@/model/article';
import theme from '@/index.module.scss';
import DateFormat from '@/components/shared/date-format';
import GenreBadge from '@/components/shared/genre-badge';
import AgedDateFormat from '@/components/shared/aged-date-format';
import KeywordBadge from '@/components/shared/keyword-badge';
import TaggedHeadline from '@/components/shared/tagged-headline';
import UpgradePanel from '@/components/shared/upgrade-panel';

import styles from '@/components/articles/article-list.module.scss';

interface Props {
  id: string;
  articles?: Article[];
  onArticleClick: Function;
  colorizeDates?: boolean;

  showGenreTags?: boolean;

  hideAuthor?: boolean;

  showKeywords?: boolean;
}

export default function ArticleList({
  id,
  articles,
  onArticleClick,
  colorizeDates,
  showGenreTags,
  hideAuthor,
  showKeywords,
}: Props) {
  if (articles === undefined)
    return (
      <div style={{ textAlign: 'center' }}>
        <GridLoader color={theme.info} />
      </div>
    );

  if (articles.length === 0)
    return (
      <UpgradePanel
        heading={'Contact us to read more'}
        leadMessage={'I would like access to MNI articles'}
      />
    );

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return styles.flashArticle;

    return '';
  };

  return (
    <div className={'h-100 container-fluid'}>
      {articles?.map((value, index) => {
        let scale = 1;

        if (ArticleUtil.hasGenre(value, 'HEADLINE')) scale = 10;
        if (ArticleUtil.hasGenre(value, 'BULLET')) scale = 4;

        return (
          <div
            key={'article-' + value.uri + '-' + index + '-' + id}
            className={`row ${flash(index, value.versioncreated)}`}
            onClick={event => onArticleClick(value)}
          >
            <div className={'col-2 align-top  text-muted small'}>
              {colorizeDates ? (
                <AgedDateFormat format={'MMM D h:mma'} date={value.versioncreated} scale={scale} />
              ) : (
                <DateFormat format={'MMM D h:mma'} date={value.versioncreated} />
              )}
            </div>
            <div className={`col align-top text-text-dark ${styles.article}`}>
              {showGenreTags &&
                value.genre.map(value => (
                  <span key={'genre-' + value.code + '-' + id} className={'small'}>
                    <GenreBadge genre={value} />
                  </span>
                ))}

              <span className={styles.articleTitle}>
                {' '}
                <TaggedHeadline headline={value.headline} />
              </span>
              <div
                dangerouslySetInnerHTML={{ __html: value.summary }}
                className={`small  lh-sm fst-italic fw-lighter ${styles.articleSummary}`}
              ></div>

              {hideAuthor || (
                <div>
                  <span className={`align-top ${styles.articleAuthor}`}>by {value.byline}</span>
                </div>
              )}

              {showKeywords && (
                <div>
                  {value.keywords.map(s => (
                    <span className={'small'} key={s}>
                      <KeywordBadge keyword={s} />{' '}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
