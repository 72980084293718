import { WidgetDockAdapter } from '@/components/dock/widget-dock-adapter';
import { BulletListWidget } from '@/widgets/widgets/scrollers/bullet-list-widget';
import { DataCalendarWidget } from '@/widgets/widgets/calendars/data-calendar-widget';
import { HotListWidget } from '@/widgets/widgets/hot-list-widget';
import { SettingsMenuWidget } from '@/widgets/widgets/settings-menu-widget';
import { SearchWidget } from '@/widgets/widgets/search-widget';
import { MainwireScrollerWidget } from '@/widgets/widgets/scrollers/mainwire-scroller-widget';
import { NewsWidget } from '@/widgets/news-widget';
import { BookmarksWidget } from '@/widgets/widgets/bookmarks-widget';
import { SingleCalendarWidgetFactory } from '@/widgets/widgets/calendars/single-calendar-widget';
import { NewsWidgetFactory } from '@/widgets/news-widget-factory';
import { PinnedArticleWidgetFactory } from '@/widgets/widgets/pinned/pinned-article-widget-factory';
import { PinnedSeriesWidget } from '@/widgets/widgets/pinned/pinned-series-widget';
import { PinnedSeriesWidgetFactory } from '@/widgets/widgets/pinned/pinned-series-widget-factory';

const widgets = [
  new BulletListWidget('all-bullets', 'All Bullets', 'All'),
  new BulletListWidget('fi-bullets', 'FI Bullets', 'FI'),
  new BulletListWidget('fx-bullets', 'FX Bullets', 'FX'),
  new BulletListWidget('oil-bullets', 'Oil Bullets', 'Oil'),
  new BulletListWidget('power-bot', 'Power Bullets', 'Power'),
  new BulletListWidget('gas-bullets', 'Gas Bullets', 'Gas'),

  new DataCalendarWidget(),
  new HotListWidget(),
  new SettingsMenuWidget(),
  new SearchWidget(),
  new MainwireScrollerWidget(),
  new BookmarksWidget(),
];

const templates = [
  new SingleCalendarWidgetFactory(),
  new PinnedArticleWidgetFactory(),
  new PinnedSeriesWidgetFactory(),
];

export interface WidgetFactory {
  getWidgetById(id: string): NewsWidget | undefined;

  getStaticWidgetList(): NewsWidget[];

  getDynamicWidgetFactories(): NewsWidgetFactory[];
}

export default function useWidgetFactory(): WidgetFactory {
  return {
    getWidgetById(id: string) {
      let template = templates.find(value => value.matchesTemplate(id));

      if (template) return template.createInstance(id);

      return widgets.find(value => value.id == id);
    },

    getStaticWidgetList(): NewsWidget[] {
      return widgets;
    },
    getDynamicWidgetFactories(): NewsWidgetFactory[] {
      return templates;
    },
  };
}
