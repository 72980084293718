import React, { useContext, useEffect, useState } from 'react';
import { GridLoader } from 'react-spinners';
import dayjs from 'dayjs';
import useSubscription from '@/hooks/use-subscription';
import useTimer from '@/hooks/use-timer';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import UpgradePanel from '@/components/shared/upgrade-panel';
import PageControl from '@/components/shared/page-control';
import { articleApi, sectionApi } from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { Section } from '@/model/news/section';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConceptItemFilters from '@/components/scrollers/concept-item-filters';

interface ScrollerProps {
  section: string;
  articles?: Article[];

  onArticleClick: Function;
  dark?: boolean;
  title?: JSX.Element;
  onArticlesChange: (articles?: Article[]) => void;
}

export default function Scroller(props: React.PropsWithChildren<ScrollerProps>) {
  const [articles, setArticles] = useState<Article[]>();
  const [offset, setOffset] = useState<number>(0);
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [section, setSection] = useState<Section>();
  const [excludedSubjects, setExcludedSubjects] = useState<string[]>([]);
  const [excludedGenres, setExcludedGenres] = useState<string[]>([]);

  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);

  const fetchData = (offset: number) => {
    articleApi
      .getArticlesBySection(props.section, {
        offset: offset,
        limit: 15,
        excludeSubjects: excludedSubjects,
        excludeGenres: excludedGenres,
      })
      .then(
        value => {
          setArticles(value.content);
          setForbidden(false);
        },
        reason => {
          setForbidden(true);
        },
      );
  };

  const setPage = (offset: number) => {
    setArticles(undefined);
    setOffset(offset);
    fetchData(offset);
  };

  useSubscription<Article>(
    MessageServiceConstants.NEWS_ARTICLE_TOPIC,
    (article: Article, headers) => {
      const sections: string[] = JSON.parse(headers.sections);

      console.debug('Received article', sections, article);

      if (sections.includes(props.section)) {
        console.debug('Adding article');
        const a = articles || [];

        const i = articles?.findIndex(value => value.uri === article.uri);

        if (i == null || i < 0) {
          console.debug('Adding article');
          a.unshift(article);
          a.pop();
        } else {
          console.debug('Updating article', i);
          a[i] = article;
        }

        setArticles([...a]);
      }
    },
  );

  useEffect(() => {
    setOffset(0);
    setArticles(undefined);
    fetchData(offset);
    sectionApi.getSectionByCode(props.section).then(value => {
      setSection(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section]);

  useEffect(() => {
    props.onArticlesChange(articles);
  }, [articles]);

  useEffect(() => {
    fetchData(offset);
  }, [excludedSubjects, excludedGenres]);

  const titleChange = (s?: string) => {
    if (!s) widgetContainer.setTitle(undefined);
    else widgetContainer.setTitle(s);
  };

  useTimer(
    30000,
    () => {
      fetchData(offset);
    },
    false,
  );

  if (forbidden)
    return (
      <UpgradePanel
        heading={'Contact us to read more'}
        leadMessage={'I would like to try: ' + props.section}
      />
    );

  return (
    <div className={'container-element h-100 '}>
      <div className={'h-100 d-flex flex-column container-responsive-text '}>
        <PageControl
          offset={offset}
          onChange={setPage}
          title={
            <>
              {section && (
                <ConceptItemFilters
                  conceptItems={section.subjects}
                  onExcludedSubjectsChange={setExcludedSubjects}
                  onExcludedGenresChange={setExcludedGenres}
                  onFilteredDataNameChange={titleChange}
                />
              )}
            </>
          }
        />

        {!articles && (
          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>
        )}

        <div className={'flex-fill mt-3 overflow-auto '}>{props.children}</div>
      </div>
    </div>
  );
}
